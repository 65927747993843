import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid, Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel, FormControl, InputLabel, Select, MenuItem, InputAdornment, CircularProgress, Divider } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BuildIcon from '@mui/icons-material/Build';
import EuroIcon from '@mui/icons-material/Euro';
import RedeemIcon from '@mui/icons-material/Redeem';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { lifecyclePhases, getColorForPhase } from '../../helpers/utils';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { FreshBooksService } from '../../services/freshbooks/freshbbooks.service';

const Summary = ({ global }) => {
    const [open, setOpen] = useState(false);
    const [popupContent, setPopupContent] = useState({ title: '', companies: [] });
    const [selectedLifecycle, setSelectedLifecycle] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [recapData, setRecapData] = useState([]);
    const [priceproduct, setPriceproduct] = useState({});
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const sortByDate = (companies) => {
        if (!Array.isArray(companies)) {
            return [];
        }
        return companies.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    };

    const handleSort = (key) => {
        setSortConfig((prevConfig) => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
        }));
    };

    const produitsSum = global?.produits?.produits && global?.produits?.produits.length > 0
        ? global?.produits?.produits?.reduce((sum, produit) => {
            const fbProductsDetails = produit.fbProductsDetails ? JSON.parse(produit.fbProductsDetails) : null;
            if (fbProductsDetails && fbProductsDetails.cost) {
                const totalHT = parseFloat(fbProductsDetails.cost.totalHT);
                const discountValue = parseFloat(fbProductsDetails.cost.discountValue) || 0;
                const amount = totalHT - discountValue;
                return sum + (isNaN(amount) ? 0 : amount);
            }
            return sum;
        }, 0)
        : 0;

    const abonnementsSum = global?.abonnements?.abonnements && global?.abonnements?.abonnements.length > 0
        ? global?.abonnements?.abonnements?.reduce((sum, abonnement) => {
            const fbProductsDetails = abonnement.fbProductsDetails ? JSON.parse(abonnement.fbProductsDetails) : null;
            if (fbProductsDetails && fbProductsDetails.cost) {
                const totalHT = parseFloat(fbProductsDetails.cost.totalHT);
                const discountValue = parseFloat(fbProductsDetails.cost.discountValue) || 0;
                const amount = totalHT - discountValue;
                return sum + (isNaN(amount) ? 0 : amount);
            }
            return sum;
        }, 0)
        : 0;

    const groupedByCompany = popupContent?.companies?.reduce((acc, abonnement) => {
        const id = abonnement.fbHubspotCompanyId;
        const companyName = abonnement.fbCompanyName;
        const owner = abonnement.fbHubspotOwner;
        const companyId = abonnement.fbHubspotCompanyId;

        if (!acc[companyId]) {
            acc[companyId] = {
                id,
                companyName,
                owners: new Set(),
                products: [],
                totalAmount: 0
            };
        }

        // Ajouter le propriétaire sans doublon
        acc[companyId].owners.add(owner);

        // Vérifier si fbProductsDetails existe avant d'y accéder
        if (abonnement.fbProductsDetails) {
            try {
                const parsedDetails = JSON.parse(abonnement.fbProductsDetails);
                const products = parsedDetails.products || [];
                products.forEach(product => {
                    const totalHT = parseFloat(parsedDetails.cost.totalHT) || 0; // Vérification si totalHT est défini
                    const discountValue = parseFloat(parsedDetails.cost.discountValue) || 0; // Vérification si discountValue est défini

                    acc[companyId].products.push({
                        name: product.name,
                        qty: product.qty,
                        totalHT,
                        discountValue
                    });

                    // Ajouter au montant total de l'entreprise
                    acc[companyId].totalAmount += (totalHT - discountValue);
                });
            } catch (error) {
                console.error("Erreur lors du parsing de fbProductsDetails pour l'entreprise :", companyId, error);
            }
        }
        return acc;
    }, {});


    const companyData = Object.values(groupedByCompany).map(company => {
        const ownersList = Array.from(company.owners).join(', '); // Jointure des propriétaires sans doublon

        // Préparer les détails des produits
        const productDetails = company?.products?.reduce((acc, product) => {
            if (!acc[product.name]) {
                acc[product.name] = { qty: 0, totalHT: 0, discountValue: 0 };
            }
            acc[product.name].qty += product.qty;
            acc[product.name].totalHT += product.totalHT;
            acc[product.name].discountValue += product.discountValue;
            return acc;
        }, {});

        return {
            id: company.id,
            companyName: company.companyName,
            ownersList,
            productDetails,
            totalAmount: company.totalAmount
        };
    });



    useEffect(() => {
        if (!popupContent?.companies) return; // Assurer que companies existe

        const recapProducts = popupContent?.companies?.reduce((acc, abonnement) => {
            if (abonnement.fbProductsDetails) {
                const products = JSON.parse(abonnement.fbProductsDetails).products || [];

                // Parcourir chaque produit et accumuler les quantités par nom
                products.forEach(product => {
                    const productName = product.name;
                    const productQty = parseInt(product.qty, 10) || 0; // Quantité de chaque produit

                    if (!acc[productName]) {
                        acc[productName] = { qty: 0, totalHT: 0, discountValue: 0 }; // Initialiser si le produit n'existe pas
                    }

                    // Ajouter la quantité de ce produit à l'accumulateur
                    acc[productName].qty += productQty;

                    // Calculer le total HT en multipliant la quantité par le prix unitaire du produit
                    const unitPrice = parseFloat(priceproduct[productName]) || 0; // Récupérer le prix unitaire du produit
                    const productTotalHT = unitPrice * productQty; // Calculer le total HT pour ce produit

                    // Ajouter au totalHT
                    acc[productName].totalHT += productTotalHT;

                    // Si fbProductsDetails contient un coût et une valeur de réduction, les récupérer
                    const fbProductsDetails = JSON.parse(abonnement.fbProductsDetails);
                    const totalHT = parseFloat(fbProductsDetails.cost.totalHT) || 0;
                    const discountValue = parseFloat(fbProductsDetails.cost.discountValue) || 0;

                    // Calculer le montant après réduction
                    const amount = totalHT - discountValue;

                    // Ajouter la valeur de la réduction à l'accumulateur
                    acc[productName].discountValue += discountValue; // Ajouter la réduction cumulée
                });
            }
            return acc;
        }, {});

        // Convertir l'objet en tableau pour l'affichage
        const recapDataArray = Object.entries(recapProducts).map(([name, data]) => {
            const amount = data.totalHT - data.discountValue; // Calculer l'amount total pour chaque produit

            return {
                name,
                qty: data.qty,
                totalHT: data.totalHT.toFixed(2) + ' €', // Total HT formaté
                amount: amount.toFixed(2) + ' €' // Montant après réduction formaté
            };
        });

        // Ajouter la ligne TOTAL
        const totalQty = recapDataArray?.reduce((sum, item) => sum + item.qty, 0);
        const totalHT = recapDataArray?.reduce((sum, item) => sum + parseFloat(item.totalHT), 0);
        const totalAmount = recapDataArray?.reduce((sum, item) => sum + parseFloat(item.amount), 0);

        recapDataArray.push({
            name: "Total",
            qty: totalQty,
            totalHT: totalHT.toFixed(2) + ' €',
            amount: totalAmount.toFixed(2) + ' €' // Total après réduction
        });

        setRecapData(recapDataArray); // Mettre à jour le state avec les données à afficher
    }, [popupContent?.companies, priceproduct]);

    const productsGroupedByCompany = popupContent?.companies?.reduce((acc, product) => {
        const companyId = product.fbHubspotCompanyId;
        const companyName = product.fbCompanyName;
        const owner = product.fbHubspotOwner;

        // Initialisation si l'entreprise n'existe pas encore
        if (!acc[companyId]) {
            acc[companyId] = {
                companyName,
                owners: new Set(),
                products: [], // Contiendra les produits sans doublons
                totalAmount: 0, // Montant total
            };
        }

        // Ajouter le propriétaire sans doublon
        acc[companyId].owners.add(owner);

        // Vérifier si fbProductsDetails existe avant d'y accéder
        if (product.fbProductsDetails) {
            try {
                const parsedDetails = JSON.parse(product.fbProductsDetails);
                const products = parsedDetails.products || [];
                const totalHT = parseFloat(parsedDetails.cost.totalHT) || 0; // Coût total de l'entreprise
                const discountValue = parseFloat(parsedDetails.cost.discountValue) || 0; // Réduction
                const netAmount = totalHT - discountValue; // Montant après réduction
                acc[companyId].totalAmount += netAmount; // Assurer qu'on ajoute correctement le montant total

                // Parcours des produits et ajout
                products.forEach(p => {
                    const existingProduct = acc[companyId].products.find(prod => prod.name === p.name);

                    if (existingProduct) {
                        existingProduct.qty += p.qty;
                    } else {
                        acc[companyId].products.push({
                            name: p.name,
                            qty: p.qty,
                            totalHT,
                            discountValue
                        });
                    }
                });
            } catch (error) {
                console.error("Erreur lors du parsing de fbProductsDetails pour l'entreprise :", companyId, error);
            }
        }

        return acc;
    }, {});

    // Transformation des données pour la table finale
    const productsTable = Object.keys(productsGroupedByCompany).map((companyId) => {
        const companyData = productsGroupedByCompany[companyId];

        const companyName = companyData.companyName;
        const ownersList = companyData.owners.size > 0 ? Array.from(companyData.owners).join(', ') : 'No owners';
        const products = companyData.products || [];

        return {
            id:companyId,
            companyName,
            products,
            ownersList,
            totalAmount: companyData.totalAmount.toFixed(2), // Formater le montant total à 2 décimales
        };
    });




    const dashboardData = [
        {
            icon: <VisibilityIcon style={{ fontSize: 40, color: '#9370DB' }} />,
            title: 'Visites',
            value: global?.visites?.companies.length,
            footer: 'Liste de visites',
            companies: global?.visites?.companies,
            footerStyle: { color: '#d32f2f' },
        },
        {
            icon: <CheckCircleIcon style={{ fontSize: 40, color: '#2196F3' }} />,
            title: 'Obtenus',
            value: global?.accords?.companies.length,
            companies: sortByDate(global?.accords?.companies),
            footer: 'Liste d\'accords',
        },
        {
            icon: <VerifiedUserIcon style={{ fontSize: 40, color: '#4CAF50' }} />,
            title: 'Validés',
            value: global?.accords_valide?.companies.length,
            companies: sortByDate(global?.accords_valide?.companies),
            footer: 'Liste d\'accords',
        },
        {
            icon: <BuildIcon style={{ fontSize: 40, color: '#F44336' }} />,
            title: 'Installations',
            value: global?.installation?.companies.length,
            companies: sortByDate(global?.installation?.companies),
            footer: 'Liste d\'installations',
        },
        {
            icon: <EuroIcon style={{ fontSize: 40, color: '#FF9800' }} />,
            title: 'Abonnements',
            value: abonnementsSum !== 0 ? `${abonnementsSum.toFixed(2)} €` : '0',
            companies: sortByDate(global?.abonnements?.abonnements),
            footer: 'Détails des abonnements',
        },
        {
            icon: <EuroIcon style={{ fontSize: 40, color: '#FF9800' }} />,
            title: 'Produits',
            value: produitsSum !== 0 ? `${produitsSum.toFixed(2)} €` : '0',
            companies: sortByDate(global?.produits?.produits),
            footer: 'Détails des produits',
        },
    ];

    const handleFooterClick = (title, companies) => {
        setPopupContent({ title, companies });
        setOpen(true);
    };

    const handleRedirectToCompany = (companyId) => {
        const baseUrl = window.location.origin;
        const url = `${baseUrl}/home?q=${companyId}`;
        window.open(url, '_blank');
    };

    const getLifecycleLabel = (value) => {
        const phase = lifecyclePhases.find(phase => phase.value === value);
        return phase ? phase.label : "";
    };

    const setSelectedLyfeCycle = (e) => {
        setSelectedLifecycle(e);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const sortedCompanies = [...popupContent?.companies]
        .filter((company) =>
            selectedLifecycle ? company.previous_lifecyclestage === selectedLifecycle : true
        )
        .sort((a, b) => {
            if (!sortConfig.key) return 0;

            let aValue = a[sortConfig.key]?.toLowerCase();
            let bValue = b[sortConfig.key]?.toLowerCase();

            if (sortConfig.key === 'previous_lifecyclestage') {
                aValue = getLifecycleLabel(aValue);
                bValue = getLifecycleLabel(bValue);
            }
            if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
            return 0;
        });


    useEffect(() => {
        const fetchProducts = async () => {
            const productsData = await FreshBooksService.getAllServices();

            // Créer un objet priceproduct avec le prix de chaque produit
            const priceObj = {};
            productsData.forEach((product) => {
                priceObj[product.name] = product.rate.amount;
            });

            setPriceproduct(priceObj); // Mettre à jour le state avec les prix des produits
        };

        fetchProducts();
    }, []);
    return (
        <Box pt={2} pb={2}>
            <Grid container spacing={3}>
                {dashboardData.map((data, index) => (
                    <Grid item xs={12} sm={6} md={2} key={index}>
                        <Card>
                            <Box display="flex" alignItems="center" p={2}>
                                <Box mr={2}>{data.icon}</Box>
                                <CardContent>
                                    <Typography variant="subtitle2" color="textSecondary" pb={1.2}>
                                        {data.title}
                                    </Typography>
                                    <Typography variant="h5" fontWeight="bold">
                                        {data.value}
                                    </Typography>
                                    <Typography
                                        pt={1.4}
                                        variant="body1"
                                        sx={{
                                            ...data.footerStyle,
                                            fontSize: '.6rem',
                                            cursor: 'pointer',
                                            whiteSpace: 'nowrap',     // Garde le texte sur une seule ligne
                                            overflow: 'hidden',      // Cache tout ce qui déborde
                                            textOverflow: 'ellipsis', // Ajoute "..." si le texte est trop long
                                            display: 'block',         // Assure que les styles fonctionnent
                                            width: '100%',            // S'assure que le conteneur a une largeur définie
                                        }}
                                        onClick={() => handleFooterClick(data.footer, data.companies)}
                                    >
                                        {data.footer}
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Dialog open={open} onClose={handleClose} sx={{
                '& .MuiDialog-paper': {
                    width: isMobile ? '96%' : 600,
                    margin: isMobile ? 2 : 'auto',

                    height: isMobile ? 'calc(100vh - 200px)' : 650,
                },
            }}>
                <DialogTitle
                    color='warning'
                    sx={{
                        padding: 2,
                        textAlign: 'center',
                        fontWeight: 600,
                        textTransform: 'uppercase'  // Mettre le texte en majuscules
                    }}
                >
                    {popupContent.title}
                </DialogTitle>

                {/* Champ de filtre par cycle de vie */}
                {popupContent.title === 'Liste de visites' &&
                    <Box p={2}>
                        <FormControl fullWidth>
                            <InputLabel>Phase de cycle de vie</InputLabel>
                            <Select
                                value={selectedLifecycle}
                                label="Phase de cycle de vie"
                                onChange={(e) => setSelectedLyfeCycle(e.target.value)}
                                style={{ color: getColorForPhase(selectedLifecycle) }}
                                endAdornment={
                                    lifecyclePhases && (
                                        <InputAdornment position="end">
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    width: '100%',
                                                    height: '100%',
                                                }}
                                            >
                                            </div>
                                        </InputAdornment>
                                    )
                                }
                            >
                                <MenuItem key={0} value={''}>
                                    <span style={{
                                        display: 'inline-block',
                                        width: '10px',
                                        height: '10px',
                                        borderRadius: '50%',
                                        marginRight: '8px'
                                    }}></span>
                                    Tous
                                </MenuItem>
                                {lifecyclePhases
                                    .map(phase => (
                                        <MenuItem key={phase.value} value={phase.value}>
                                            <span style={{
                                                display: 'inline-block',
                                                width: '10px',
                                                height: '10px',
                                                borderRadius: '50%',
                                                backgroundColor: phase.color,
                                                marginRight: '8px'
                                            }}></span>
                                            {phase.label}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Box>
                }
                <DialogContent sx={{ padding: 2 }}>
                    <Table>
                        {popupContent.title === 'Liste de visites' ? (
                            <>
                                {/* Tableau des entreprises */}
                                <Table>
                                    <TableHead>
                                        {popupContent.companies.length > 0 && (
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: 600, padding: 1 }}>
                                                    <TableSortLabel
                                                        active={sortConfig.key === 'owner'}
                                                        direction={sortConfig.key === 'owner' ? sortConfig.direction : 'asc'}
                                                        onClick={() => handleSort('owner')}
                                                    >
                                                        ENTREPRISE
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell sx={{ fontWeight: 600, padding: 1 }}>
                                                    <TableSortLabel
                                                        active={sortConfig.key === 'previous_lifecyclestage'}
                                                        direction={sortConfig.key === 'previous_lifecyclestage' ? sortConfig.direction : 'asc'}
                                                        onClick={() => handleSort('previous_lifecyclestage')}
                                                    >
                                                        CYCLE DE VIE
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell sx={{ fontWeight: 600, padding: 1 }}>
                                                    <TableSortLabel
                                                        active={sortConfig.key === 'createdAt'}
                                                        direction={sortConfig.key === 'createdAt' ? sortConfig.direction : 'asc'}
                                                        onClick={() => handleSort('createdAt')}
                                                    >
                                                        DATE ET HEURE
                                                    </TableSortLabel>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableHead>
                                    <TableBody>
                                        {sortedCompanies.map((company, index) => (
                                            <TableRow key={`${company.id}-${index}`}>
                                                <TableCell
                                                    onClick={() => handleRedirectToCompany(company.id)}
                                                    sx={{ padding: 1, cursor: 'pointer', color: '#4caf50' }}
                                                >
                                                    {company.name}
                                                    <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'gray' }}>
                                                        {company.owner}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{ padding: 1 }}>
                                                    {getLifecycleLabel(company.previous_lifecyclestage)}
                                                </TableCell>
                                                <TableCell sx={{ padding: 1 }}>
                                                    {new Date(company.createdAt).toLocaleString('fr-FR', {
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        day: '2-digit',
                                                        month: '2-digit',
                                                        year: 'numeric',
                                                    })}
                                                </TableCell>
                                            </TableRow>
                                        ))}

                                        {/* Gestion de l'affichage si aucune entreprise ne correspond au filtre */}
                                        {sortedCompanies.length === 0 && (
                                            <TableRow>
                                                <TableCell colSpan={3} align="center">
                                                    <Typography>Pas de détails</Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </>
                        ) : popupContent.title === 'Détails des abonnements' ? (
                            <>
                                {/* Tableau des produits récapitulatif */}
                                <Typography
                                    variant="h6"
                                    sx={{
                                        marginBottom: 1,
                                        fontWeight: 'bold',
                                        color: '#4caf50',
                                        textAlign: 'center'  // Centrer le texte
                                    }}
                                >
                                    RECAP
                                </Typography>

                                <Table sx={{ border: '1px solid #ddd', marginBottom: '24px' }}>
                                    <TableHead sx={{ backgroundColor: '#f4f4f4' }}>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: 600, padding: 1 }}>SERVICES</TableCell>
                                            <TableCell sx={{ fontWeight: 600, padding: 1 }}>QTE</TableCell>
                                            <TableCell sx={{ fontWeight: 600, padding: 1 }}>CA</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {recapData.length > 0 ? (
                                            recapData.map((product, index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={product.name === "Total" ? { backgroundColor: '#e8f5e9', fontWeight: 'bold' } : {}}
                                                >
                                                    <TableCell sx={{ padding: 1, fontWeight: product.name === "Total" ? 'bold' : 'normal' }}>
                                                        {product.name}
                                                    </TableCell>
                                                    <TableCell sx={{ padding: 1, fontWeight: product.name === "Total" ? 'bold' : 'normal' }}>
                                                        {product.qty}
                                                    </TableCell>
                                                    <TableCell sx={{ padding: 1, fontWeight: product.name === "Total" ? 'bold' : 'normal' }}>
                                                        {product.amount}
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={3} sx={{ textAlign: 'center', padding: 1 }}>
                                                    Aucun service vendu
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>

                                {/* Marge entre les tables */}
                                <div style={{ marginBottom: '26px' }}></div>

                                {/* Tableau des entreprises */}
                                <Typography
                                    variant="h6"
                                    sx={{
                                        marginBottom: 1,
                                        fontWeight: 'bold',
                                        color: '#4caf50',
                                        textAlign: 'center',  // Centrer le texte
                                        textTransform: 'uppercase'  // Mettre le texte en majuscules
                                    }}
                                >
                                    Entreprises et services
                                </Typography>

                                <Table sx={{ border: '1px solid #ddd' }}>
                                    <TableHead sx={{ backgroundColor: '#f4f4f4' }}>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: 600, padding: 1 }}>ENTREPRISE</TableCell>
                                            <TableCell sx={{ fontWeight: 600, padding: 1 }}>SERVICES VENDUS</TableCell>
                                            <TableCell sx={{ fontWeight: 600, padding: 1 }}>QTE</TableCell>
                                            <TableCell sx={{ fontWeight: 600, padding: 1 }}>MONTANT</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {companyData?.map((company, index) => (
                                            <TableRow key={index}>
                                                {/* Colonne Entreprise */}
                                                <TableCell
                                                    onClick={() => handleRedirectToCompany(company.id)}
                                                    sx={{ padding: 1, cursor: 'pointer', color: '#4caf50' }}
                                                >
                                                    {company.companyName}
                                                    <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'gray' }}>
                                                        {company.ownersList}
                                                    </Typography>
                                                </TableCell>

                                                {/* Colonne Services Vendus */}
                                                <TableCell sx={{ padding: 1 }}>
                                                    {Object.keys(company.productDetails).map((productName, i) => (
                                                        <div key={i}>- {productName}</div>
                                                    ))}
                                                </TableCell>

                                                {/* Colonne Quantité */}
                                                <TableCell sx={{ padding: 1 }}>
                                                    {Object.values(company.productDetails).map((product, i) => (
                                                        <div key={i}>{product.qty}</div>
                                                    ))}
                                                </TableCell>

                                                {/* Colonne Montant */}
                                                <TableCell sx={{ padding: 1 }}>
                                                    {company.totalAmount.toFixed(2)} €
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </>
                        ) : popupContent.title === 'Détails des produits' ? (
                            <>
                                <Table sx={{ border: '1px solid #ddd' }}>
                                    <TableHead sx={{ backgroundColor: '#f4f4f4' }}>
                                        {popupContent?.companies.length > 0 && (
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: 600, padding: 1 }}>
                                                    <TableSortLabel
                                                        active={sortConfig.key === 'owner'}
                                                        direction={sortConfig.key === 'owner' ? sortConfig.direction : 'asc'}
                                                        onClick={() => handleSort('owner')}
                                                    >
                                                        ENTREPRISE
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell sx={{ fontWeight: 600, padding: 1 }}>
                                                    <TableSortLabel
                                                        active={sortConfig.key === 'previous_lifecyclestage'}
                                                        direction={sortConfig.key === 'previous_lifecyclestage' ? sortConfig.direction : 'asc'}
                                                        onClick={() => handleSort('previous_lifecyclestage')}
                                                    >
                                                        PRODUITS VENDUS
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell sx={{ fontWeight: 600, padding: 1 }}>
                                                    <TableSortLabel
                                                        active={sortConfig.key === 'createdAt'}
                                                        direction={sortConfig.key === 'createdAt' ? sortConfig.direction : 'asc'}
                                                        onClick={() => handleSort('createdAt')}
                                                    >
                                                        QTE
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell sx={{ fontWeight: 600, padding: 1 }}>
                                                    <TableSortLabel
                                                        active={sortConfig.key === 'createdAt'}
                                                        direction={sortConfig.key === 'createdAt' ? sortConfig.direction : 'asc'}
                                                        onClick={() => handleSort('createdAt')}
                                                    >
                                                        MONTANT
                                                    </TableSortLabel>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableHead>
                                    <TableBody>
                                        {productsTable?.map((company, index) => (
                                            <TableRow key={`${company?.companyName}-${index}`}>
                                                <TableCell
                                                    onClick={() => handleRedirectToCompany(company?.id)} // Assurez-vous que `id` existe si vous voulez rediriger
                                                    sx={{ padding: 1, cursor: 'pointer', color: '#4caf50' }}
                                                >
                                                    {company?.companyName}
                                                    <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'gray' }}>
                                                        {company?.ownersList}
                                                    </Typography>
                                                </TableCell>
                                                {/* Liste des produits */}
                                                <TableCell sx={{ padding: 1 }}>
                                                    {company?.products?.map((product, productIndex) => (
                                                        <div key={productIndex}>
                                                            - {product.name}
                                                        </div>
                                                    ))}
                                                </TableCell>
                                                {/* Quantité des produits */}
                                                <TableCell sx={{ padding: 1 }}>
                                                    {company?.products?.map((product, productIndex) => (
                                                        <div key={productIndex}>
                                                            {product.qty}
                                                        </div>
                                                    ))}
                                                </TableCell>
                                                {/* Montant total : utilise le total déjà calculé */}
                                                <TableCell sx={{ padding: 1 }}>
                                                    {company?.totalAmount} €
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {/* Gestion de l'affichage si aucune entreprise ne correspond au filtre */}
                                        {productsTable.length === 0 && (
                                            <TableRow>
                                                <TableCell colSpan={4} align="center">
                                                    <Typography>Pas de détails</Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>

                            </>
                        ) : popupContent.title === 'Liste d\'installations' ? (
                            <>
                                <TableHead>
                                    {popupContent?.companies.length > 0 && (
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: 600, padding: 1 }}>ENTREPRISE</TableCell>
                                            <TableCell sx={{ fontWeight: 600, padding: 1 }}>
                                                PRIX HT
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 600, padding: 1 }}>DATE ET HEURE</TableCell>
                                        </TableRow>
                                    )}
                                </TableHead>
                                <TableBody>
                                    {popupContent?.companies.length > 0 ? (
                                        popupContent?.companies.map((company) => (
                                            <TableRow key={company.id}>
                                                <TableCell onClick={() => handleRedirectToCompany(company.id)} sx={{ padding: 1, cursor: 'pointer', color: '#4caf50' }}>
                                                    {company.name}
                                                    <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'gray' }}>
                                                        {company.owner}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{ padding: 1 }}>
                                                    {(() => {
                                                        try {
                                                            const details = JSON.parse(company?.fbProductsDetails);
                                                            const totalHT = parseFloat(details?.cost?.totalHT || "0");
                                                            const discountValue = parseFloat(details?.cost?.discountValue || "0");
                                                            const finalPrice = (totalHT - discountValue).toFixed(2);
                                                            return finalPrice !== "0.00" ? `${finalPrice} €` : "0";
                                                        } catch (error) {
                                                            return "N/A"; // En cas d'erreur de parsing
                                                        }
                                                    })()}
                                                </TableCell>

                                                <TableCell sx={{ padding: 1 }}>
                                                    {new Date(company.createdAt).toLocaleString('fr-FR', {
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        day: '2-digit',
                                                        month: '2-digit',
                                                        year: 'numeric',
                                                    })}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={3} align="center">
                                                <Typography>Pas de détails</Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </>
                        ) : (
                            <>
                                <TableHead>
                                    {popupContent?.companies.length > 0 && (
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: 600, padding: 1 }}>ENTREPRISE</TableCell>
                                            <TableCell sx={{ fontWeight: 600, padding: 1 }}>
                                                PROSPECTEUR
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 600, padding: 1 }}>DATE ET HEURE</TableCell>
                                        </TableRow>
                                    )}
                                </TableHead>
                                <TableBody>
                                    {popupContent?.companies.length > 0 ? (
                                        popupContent?.companies.map((company) => (
                                            <TableRow key={company.id}>
                                                <TableCell onClick={() => handleRedirectToCompany(company.id)} sx={{ padding: 1, cursor: 'pointer', color: '#4caf50' }}>
                                                    {company.name}
                                                </TableCell>
                                                <TableCell sx={{ padding: 1 }}>{company.owner}</TableCell>
                                                <TableCell sx={{ padding: 1 }}>
                                                    {new Date(company.createdAt).toLocaleString('fr-FR', {
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        day: '2-digit',
                                                        month: '2-digit',
                                                        year: 'numeric',
                                                    })}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={3} align="center">
                                                <Typography>Pas de détails</Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </>
                        )}
                    </Table >

                </DialogContent >
                <DialogActions style={{ justifyContent: 'center', marginBottom: 20 }}>
                    <Button onClick={handleClose} color="warning" variant="outlined" style={{ width: 150 }}>
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog >
        </Box >
    );
};

export default Summary;
