import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Modal, Box, Typography, TextField, Button, IconButton, MenuItem, DialogActions, DialogTitle, Dialog, DialogContent, CircularProgress, Grid, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { AddCircleOutlineRounded, RemoveCircleOutlineOutlined } from '@mui/icons-material';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alertActions, freshbooksActions } from '../redux/actions';
import { FreshBooksService } from "../services/freshbooks/freshbbooks.service";
import DiscountDialog from './popupComponents/AddDiscountComponents/DiscountDialog';

const InvoiceModal = ({ open, onClose, contacts, company, idCompany, addressepostal, lifecyclestage, handleLifecyclestageChange, refreshInvoiceList, filteredInvoices, fetchContacts, lastContactId}) => {
    const dispatch = useDispatch();
    const currentUserToken = localStorage.getItem("TOKEN_USER");
    const lastVisitTimeKey = `${currentUserToken}_${idCompany}_lastVisitTime`;
    const lastVisitTime = localStorage.getItem(lastVisitTimeKey) ? new Date(localStorage.getItem(lastVisitTimeKey)) : null;
    const [products, setProducts] = useState([]);
    const [taxes, setTaxes] = useState([]);
    const [items, setItems] = useState([]);
    const [client, setClient] = useState(null);
    const [selectedItem, setSelectedItem] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [selectedPaymentMode, setSelectedPaymentMode] = useState('');
    const [openConfirmationFreshDialog, setOpenConfirmationFreshDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [adresse, setAdresse] = useState('');
    const [lastestInvoice, setLastestInvoice] = useState(null);
    const [isInvoiceCreated, setIsInvoiceCreated] = useState(false);
    const [errors, setErrors] = useState({
        email: '',
        product: '',
    });
    const [discount, setDiscount] = useState(0); // Pourcentage de réduction
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [tempDiscount, setTempDiscount] = useState('');
    const [selectedContact, setSelectedContact] = useState(null);
    const [contactDetails, setContactDetails] = useState({
        firstName: '',
        lastName: '',
        email: '',
        id: '',
        phone: '',
        adresse: adresse || '',
        companyName: '',
    });
    useEffect(() => {
        if (open) {
            setSelectedContact(lastContactId);
            const contact = contacts.find((c) => c.id === lastContactId);
            setContactDetails({
                firstName: contact?.properties?.firstname || '',
                lastName: contact?.properties?.lastname || '',
                email: contact?.properties?.email || '',
                phone: contact?.properties?.phone || '',
                adresse: adresse || '',
                id: contact?.id || '',
                companyName: lastestInvoice?.fbCompanyName || company.name,
            });

            // Réinitialiser les autres états
            setItems([]);
            setSelectedItem('');
            setQuantity(1);
            setSelectedPaymentMode('');
            setErrors({
                email: '',
                product: '',
            });
        }
    }, [open, contacts, lastContactId, adresse, lastestInvoice]);

    const handleContactSelect = (event) => {
        const selectedContactId = event.target.value;
        setSelectedContact(selectedContactId);
        const contact = contacts.find((c) => c.id === selectedContactId);
        setContactDetails({
            firstName: contact?.properties?.firstname || '',
            lastName: contact?.properties?.lastname || '',
            email: contact?.properties?.email || '',
            phone: contact?.properties?.phone || '',
            adresse: adresse || '',
            id: contact?.id || '',
            companyName: lastestInvoice?.fbCompanyName || company.name,
        });
    };

    useEffect(() => {
        const lastestInvoice_ = filteredInvoices.reduce((max, current) => {
            const currentInvoiceNumber = parseInt(current.fbInvoiceNumber, 10);
            const maxInvoiceNumber = parseInt(max.fbInvoiceNumber, 10);
            return currentInvoiceNumber > maxInvoiceNumber ? current : max;
        }, filteredInvoices[0]);
        setLastestInvoice(lastestInvoice_);
    }, [open, adresse])

    useEffect(() => {
            const fetchClientCompany = async () => {
                try {
                    const clientCompany = await FreshBooksService.getClientByCompanyId(idCompany);
                    setClient(clientCompany);  
                } catch (error) {
                    console.error('Erreur lors de la récupération du client:', error);
                }
            };
        
            fetchClientCompany();
          }, [open, idCompany]);


    useEffect(() => {
        if (addressepostal) {
            setAdresse(addressepostal);
        }
    }, [addressepostal]);

    const { freshbooks } = useSelector(state => state);
    const getFreshbooksProducts = async () => {
        return FreshBooksService.getAllProducts();
    }

    const getTaxesList = async () => {
        return FreshBooksService.getTaxesList();
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [freshbooksProducts, taxesList] = await Promise.all([
                    getFreshbooksProducts(),
                    getTaxesList()
                ]);
                setProducts(freshbooksProducts || []);
                setTaxes(taxesList || []);
            } catch (error) {
                console.error('Error fetching FreshBooks data:', error);
            }
        }
        fetchData();
    }, [open]);

    // Gérer le changement des champs (modifiable par l'utilisateur)
    const handleFieldChange = (field) => (event) => {
        setContactDetails({
            ...contactDetails,
            id: selectedContact,
            [field]: event.target.value,
        });
    };

    const handleProductChange = (e) => {
        const productId = e.target.value;
        const product = products.find((p) => p.id === productId);
        if (product) {
            setSelectedItem(productId);
            addItem(product);
        }
    }

    const addItem = (item) => {
        if (item) {
            const itemTaxes = [];
            // Récupérer les taxes 1 et 2 pour le produit
            [item.tax1, item.tax2].forEach((taxId) => {
                if (taxId) {
                    const tax = taxes.find(taxItem => taxItem.id === taxId);
                    if (tax) {
                        itemTaxes.push({
                            name: tax.name,
                            amount: tax.amount
                        });
                    }
                }
            });

            setItems([
                ...items,
                {
                    ...item,
                    quantity,
                    itemTaxes
                }
            ]);
            setSelectedItem('');
            setQuantity(1);
        }
    };


    const removeItem = (index) => {
        setItems(items.filter((_, i) => i !== index));
    };

    const handleQuantityChange = (index, newQuantity) => {
        setItems(items.map((item, i) => i === index ? { ...item, quantity: newQuantity } : item));
    };

    const calculateTotal = () => {
        const total = items.reduce((sum, item) => sum + item.unit_cost.amount * item.quantity, 0);
        return total.toFixed(2);
    };

    const calculateDiscount = () => {
        const total = items.reduce((sum, item) => sum + item.unit_cost.amount * item.quantity, 0);
        return (total * (discount / 100)).toFixed(2);
    };

    const calculateTotalTax = () => {
        let totalTax = 0;
        items.forEach(item => {
            const { tax1, tax2, quantity, unit_cost } = item;
            const unitPrice = parseFloat(unit_cost?.amount);
            const discountedUnitPrice = unitPrice * (1 - discount / 100); // Appliquer la réduction sur le prix unitaire
            const itemTaxTotal = [tax1, tax2]
                .filter(taxId => taxId)
                .reduce((taxAcc, taxId) => {
                    const tax = taxes.find(t => t.id === taxId);
                    if (tax) {
                        const taxAmount = parseFloat(tax.amount) / 100;
                        const taxForItem = discountedUnitPrice * quantity * taxAmount; // Utiliser le prix réduit
                        return taxAcc + taxForItem;
                    }
                    return taxAcc;
                }, 0);

            totalTax += itemTaxTotal;
        });

        return totalTax?.toFixed(2);
    };

    const calculateTotalTTC = () => {
        const totalHT = parseFloat(calculateTotal() - calculateDiscount());
        const totalTax = parseFloat(calculateTotalTax());
        if (isNaN(totalHT) || isNaN(totalTax)) {
            return '0.00';
        }
        return (totalHT + totalTax).toFixed(2);
    };

    const updateLifeCycleStageToFreemium = async (lifecyclestage) => {
        const newPhase = "evangelist";
        await handleLifecyclestageChange(newPhase);
    }

    const handleSubmit = () => {
        setLoading(false);
        const errors = {};
        if (!contactDetails.email) {
            dispatch(alertActions.error("L'email est obligatoire."));
            return;
        }

        if (items.length === 0) {
            dispatch(alertActions.error("Vous devez ajouter au moins un produit"));
            return;
        }
        if (contactDetails) {
            setOpenConfirmationFreshDialog(true);
        }

        else {
            dispatch(alertActions.error("Veuillez remplir le contact et le nom de la société."));
            return;
        }

    };


    const createOrFetchFreshClientAndInvoice = async () => {
        setLoading(true);
        try {
            const data = {
                contact: {
                    lname: contactDetails.firstName,
                    fname: contactDetails.lastName,
                    email: contactDetails.email,
                    phone: contactDetails?.phone || '',
                    id: contactDetails?.id || '',
                    address: contactDetails?.adresse || '',
                    company: company,
                    companyName: contactDetails?.companyName || '',
                    fbClientId: client?.id|| '',
                    fbHubspotCompanyId: idCompany,
                },
                produit: {
                    items,
                    totalHorsTaxes: calculateTotal(),
                    montantTaxes: calculateTotalTax(),
                    totalTTC: calculateTotalTTC(),
                    discountValue: calculateDiscount(),
                    discount: discount
                },
                paymentMode: selectedPaymentMode
            };

            setOpenConfirmationFreshDialog(false);
            if (lifecyclestage === 'opportunity') {
                updateLifeCycleStageToFreemium(lifecyclestage);
            }
            await FreshBooksService.createInvoiceForClient(data.contact, data.produit, 'Produit', data.paymentMode, null);
            setLoading(false);
            dispatch(alertActions.success("La facture a été créée avec succès !"));
            refreshInvoiceList();
            fetchContacts();
        } catch (error) {
            setLoading(false);
            setOpenConfirmationFreshDialog(false);
            throw error.message || 'Une erreur est survenue lors de la création de la facture.';
        }
    }

    const handleConfirmationClick = () => {
        setLoading(true);
        createOrFetchFreshClientAndInvoice()
            .then(() => {
                setLoading(false);
                setIsInvoiceCreated(true);
                onClose();
            })
            .catch((error) => {
                setLoading(false);
                dispatch(alertActions.error(error.message || 'Une erreur est survenue lors de la création de la facture.'));
                setTimeout(() => {
                    onClose();
                }, 5000)
            });
    };

    useEffect(() => {
        if (isInvoiceCreated) {
            setOpenConfirmationFreshDialog(false);
        }
    }, [isInvoiceCreated]);

    const handleOpenDialog = () => {
        setTempDiscount(discount.toString()); // Initialiser avec la réduction actuelle
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const handleApplyDiscount = () => {
        const parsedDiscount = parseFloat(tempDiscount);
        if (!isNaN(parsedDiscount) && parsedDiscount >= 0 && parsedDiscount <= 100) {
            setDiscount(parsedDiscount);
            setIsDialogOpen(false);
        } else {
            alert('Veuillez entrer une réduction valide (entre 0 et 100)');
        }
    };


    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Box sx={{
                    position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
                    width: 400, bgcolor: 'background.paper', borderRadius: 2, boxShadow: 24, pl: 0, pr: 0, pb: 2, pt: 1
                }}>
                    <DialogTitle align='center'>
                        <Box
                            sx={{
                                paddingBottom: 0,
                                borderRadius: '4px',
                                textAlign: 'center',
                                textTransform: 'uppercase'
                            }}
                        >
                            Créer la facture
                        </Box>
                        <IconButton
                            aria-label="close"
                            onClick={onClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: 'grey',
                            }}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </IconButton>
                    </DialogTitle>

                    <Box sx={{
                        pl: 4, pr: 4,
                        maxHeight: '60vh',
                        overflowY: 'auto',
                    }}>
                        <Box display="flex" flexDirection="column" gap={2} mt={1} mb={2}>
                            <TextField
                                label="Nom de la société"
                                value={contactDetails.companyName}
                                onChange={handleFieldChange('companyName')}
                                fullWidth
                                size="small"
                                InputProps={{
                                    style: { height: '40px' }
                                }}
                                shrink={contactDetails.companyName !== ''}
                            />
                        </Box>
                        <Box display="flex" flexDirection="column" gap={2} mt={1} mb={2}>
                            <TextField
                                label="Adresse de facturation"
                                value={contactDetails?.adresse}
                                onChange={handleFieldChange('adresse')}
                                fullWidth
                                size="small"
                                multiline
                                minRows={1}
                                maxRows={10}
                                InputProps={{
                                    style: { height: 'auto' }
                                }}
                                shrink={contactDetails.adresse !== ''}
                            />
                        </Box>
                        {/* Sélecteur de contact */}
                        <TextField
                            select
                            label="Choisir un contact pour la facturation"
                            value={selectedContact || ''}
                            onChange={handleContactSelect}
                            size="small"
                            sx={{ mb: 2, width: '100%' }}
                        //InputLabelProps={{ shrink: true }}
                        >
                            {contacts.map((contact) => (
                                <MenuItem key={contact.id} value={contact.id}>
                                    {contact.properties.firstname} {contact.properties.lastname}
                                </MenuItem>
                            ))}
                        </TextField>

                        {/* Champs modifiables pour les informations du contact */}
                        <Box display="flex" flexDirection="column" gap={2} mb={2}>
                            <TextField
                                label="Prénom"
                                value={contactDetails.firstName}
                                onChange={handleFieldChange('firstName')}
                                fullWidth
                                size="small"
                                InputProps={{
                                    style: { height: '40px' }
                                }}
                                shrink={contactDetails.firstName !== ''}
                            />
                            <TextField
                                label="Nom"
                                value={contactDetails.lastName}
                                onChange={handleFieldChange('lastName')}
                                fullWidth
                                size="small"
                                InputProps={{
                                    style: { height: '40px' }
                                }}
                                shrink={contactDetails.lastName !== ''}
                            />
                            <TextField
                                label="Email de facturation"
                                value={contactDetails.email}
                                onChange={handleFieldChange('email')}
                                fullWidth
                                size="small"
                                InputProps={{
                                    style: { height: '40px' }
                                }}
                                shrink={contactDetails.email !== ''}
                            />

                            <TextField
                                label="Téléphone"
                                value={contactDetails.phone}
                                onChange={handleFieldChange('phone')}
                                fullWidth
                                size="small"
                                InputProps={{
                                    style: { height: '40px' }
                                }}
                                shrink={contactDetails.phone !== ''}
                            />
                        </Box>

                        {/* Liste des produits ajoutés */}
                        <Box mb={2}>
                            <Table sx={{ minWidth: 300, fontSize: '0.7rem' }}>
                                <TableHead>
                                    <TableRow sx={{ height: '18px' }}> {/* Réduire la hauteur de la ligne du header */}
                                        <TableCell sx={{ fontWeight: 'bold', fontSize: '0.7rem', width: '45%', padding: '0px 8px' }}>
                                            Nom de produit
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', fontSize: '0.7rem', width: '25%', padding: '0px 8px' }}>
                                            Quantité
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', fontSize: '0.7rem', width: '25%', padding: '0px 8px' }}>
                                            Prix HT
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', fontSize: '0.7rem', textAlign: 'center', padding: '4px 8px' }}>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {items.map((item, index) => (
                                        <TableRow key={index} sx={{ height: '20px' }}> {/* Réduire la hauteur des lignes des produits */}
                                            <TableCell sx={{ fontSize: '0.7rem', width: '45%', padding: '0px 8px' }}>
                                                {item.name}
                                            </TableCell>
                                            <TableCell sx={{ width: '15%', padding: '0px 8px' }}>
                                                <TextField
                                                    type="number"
                                                    value={item.quantity}
                                                    onChange={(e) => handleQuantityChange(index, parseInt(e.target.value))}
                                                    inputProps={{
                                                        min: 1,
                                                        style: { fontSize: '0.6rem' }
                                                    }}
                                                    sx={{ fontSize: '0.6rem', width: '90%' }}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ fontSize: '0.7rem', width: '25%', padding: '0px 8px' }}>
                                                {(item.unit_cost?.amount * item.quantity).toFixed(2)}
                                                {item.unit_cost?.code === 'EUR' ? '€' : item.unit_cost?.code}
                                            </TableCell>
                                            <TableCell sx={{ fontSize: '0.7rem', textAlign: 'center', padding: '0px 8px' }}>
                                                <IconButton onClick={() => removeItem(index)} color="warning">
                                                    <RemoveCircleOutlineOutlined />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>

                        {/* Sélection et ajout de produit */}
                        <Box display="flex" alignItems="center" gap={2} mb={2}>
                            <TextField
                                select
                                label="Sélectionner un produit"
                                value={selectedItem}
                                onChange={handleProductChange}
                                sx={{ width: '100%' }}
                                size="small"
                                InputProps={{
                                    style: { height: '40px' }
                                }}
                            //InputLabelProps={{ shrink: true }}
                            >
                                {products
                                    .filter((product) => !items.some((item) => item.id === product.id))
                                    .map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.name} - {item.unit_cost?.amount} {item.unit_cost?.code}
                                        </MenuItem>
                                    ))}
                            </TextField>
                        </Box>

                        {/* Totaux */}
                        <Box mt={3}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="subtitle1">Total hors taxe :</Typography>
                                <Typography variant="subtitle1">{calculateTotal()} {items[0]?.unit_cost?.code}</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography
                                    variant="subtitle2" // Utilisation d'une variante plus petite
                                    onClick={handleOpenDialog} // Fonction appelée lorsqu'on clique
                                    style={{
                                        cursor: 'pointer',
                                        textDecoration: 'underline',
                                        color: '#1976d2',
                                        fontStyle: 'italic',
                                        fontSize: '0.875rem',
                                        marginTop: '2px',
                                    }}
                                >
                                    {discount > 0 ? `${discount}% de remise` : 'Ajouter une réduction'}
                                </Typography>
                                <Typography
                                    variant="subtitle2" // Utilisation d'une variante plus petite
                                    style={{
                                        fontStyle: 'italic', // Italique
                                        fontSize: '0.875rem', // Taille légèrement réduite
                                        marginLeft: '8px',
                                    }}
                                >
                                    - {calculateDiscount()} {items[0]?.unit_cost?.code}
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="subtitle1">Montant de la taxe :</Typography>
                                <Typography variant="subtitle1">{calculateTotalTax()} {items[0]?.unit_cost?.code}</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="subtitle1">Total TTC :</Typography>
                                <Typography variant="subtitle1">{calculateTotalTTC()} {items[0]?.unit_cost?.code}</Typography>
                            </Box>
                        </Box>

                        {/* Mode de paiement */}
                        <Box display="flex" alignItems="center" mb={2} mt={3}>
                            <TextField
                                select
                                label="Mode de paiement"
                                value={selectedPaymentMode}
                                onChange={(e) => setSelectedPaymentMode(e.target.value)}
                                sx={{ width: '100%' }}
                                size="small"
                                InputProps={{
                                    style: { height: '40px' }
                                }}
                            //InputLabelProps={{ shrink: true }}
                            >
                                <MenuItem value="">Aucun</MenuItem>
                                <MenuItem value="Check">Paiement par chèque</MenuItem>
                                <MenuItem value="Cash">Paiement en espèces</MenuItem>
                            </TextField>
                        </Box>
                    </Box>
                    <DialogActions style={{ justifyContent: 'center', marginBottom: 0 }}>
                        <Button color="success" variant="outlined"
                            style={{
                                minWidth: 200,
                                height: 35,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                            onClick={handleSubmit}>
                            {!loading ? 'Envoyer la facture' : <CircularProgress size={20} color="inherit" />}
                        </Button>
                    </DialogActions>
                </Box>
            </Modal>
            <DiscountDialog
                open={isDialogOpen}
                onClose={handleCloseDialog}
                onApply={handleApplyDiscount}
                tempDiscount={tempDiscount}
                setTempDiscount={setTempDiscount}
            />
            <Dialog
                open={openConfirmationFreshDialog}
                onClose={() => setOpenConfirmationFreshDialog(false)}
                PaperProps={{ style: { borderRadius: 5 } }}
                maxWidth="sm"
                fullWidth
                sx={{
                    '& .MuiDialog-paper': {
                        width: '400px',
                        height: 'auto',
                        maxWidth: '100%',
                    },
                }}
            >
                <DialogTitle align="center">
                    <strong>Confirmer la sélection</strong>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body1" align="center">
                        Êtes-vous sûr de vouloir créer la facture pour la société {contactDetails.companyName} d'un montant total
                        de {calculateTotalTTC()} {items[0]?.unit_cost?.code} , à l'adresse email de facturation : <span style={{ fontWeight: 'bold' }}>{contactDetails.email}</span>
                        <br />
                    </Typography>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center', marginBottom: 20 }}>
                    <Button
                        onClick={() => setOpenConfirmationFreshDialog(false)}
                        color="warning"
                        variant="outlined"
                        style={{ width: 150 }}
                    >
                        Annuler
                    </Button>
                    <Button
                        onClick={handleConfirmationClick}
                        color="primary"
                        variant="outlined"
                        style={{ width: 150 }}
                    >
                        {!loading && "Confirmer"}
                        {loading && <CircularProgress size={20} color="inherit" />}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default InvoiceModal;
