import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Chip,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { FreshBooksService } from "../../../services/freshbooks/freshbbooks.service";
import { alertActions } from "../../../redux/actions";
import { useDispatch } from "react-redux";

const RIBDialog = ({ open, onClose, idCompany, userEmail }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [clientId, setClientId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [emailList, setEmailList] = useState([]);
  const inputRef = useRef(null);

  useEffect(() => {
    if (userEmail) {
      setEmail(userEmail);
    }
  }, [userEmail]);

  useEffect(() => {
    const fetchClientCompany = async () => {
      try {
        const clientCompany = await FreshBooksService.getClientByCompanyId(idCompany);
        setClientId(clientCompany.id)
      } catch (error) {
        console.error("Error fetching client company:", error);
      }
    };

    fetchClientCompany();
  }, [idCompany]);


  const parseEmails = (emailString) => {
    const emailsArray = emailString
      .split(/[ ,]+/)
      .map((email) => email.trim())
      .filter((email) => email && /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email));

    return emailsArray;
  };

  const handleAddEmail = () => {
    const emailsArray = parseEmails(email);

    if (emailsArray.length > 0) {
      setEmailList((prevList) => [...prevList, ...emailsArray]);
      setEmail("");
      inputRef.current?.focus();
    }
  };

  const handleDeleteEmail = (emailToDelete) => {
    setEmailList(emailList.filter((email) => email !== emailToDelete));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!clientId) {
      dispatch(alertActions.error("Le client n'est pas encore enregistré sur FreshBooks"));
      setLoading(false);
      return;
    }
    const recipients = emailList;
    if (recipients.length === 0) {
      dispatch(alertActions.error("Veuillez renseigner au moins un email."));
      setLoading(false);
      return;
    }

    const data = {
      subject,
      recipients,
      client_id: clientId,
      payment_type: "sepa-debit",
    };

    try {
      const response = await FreshBooksService.sendDirectDebitSetupForm(data);
      if (response) {
        await FreshBooksService.fbPaymentMethodSetupStatus(clientId, 1);
        dispatch(alertActions.success("Envoi de la demande de mise en place de débit direct avec succès !"));
        onClose();
      }
      setLoading(false);
    } catch (error) {
      dispatch(alertActions.error("Erreur lors de l'envoi de la demande de mise en place de débit direct"));
      setLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    const emailsArray = parseEmails(email);
    if (emailsArray.length === 1 && /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(emailsArray[0])) {
      setEmailList((prevList) => [...prevList, emailsArray[0]]);
      setEmail("");
      inputRef.current?.focus();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      sx={{
        "& .MuiDialog-paper": {
          width: "350px",
          borderRadius: "12px",
        },
      }}
    >
      <DialogTitle sx={{ position: "relative", textAlign: "center" }}>
        PRELEVEMENT AUTOMATIQUE
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "gray",
            zIndex: 1,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            mt: 0,
            pt: 0
          }}
        >
          {/* Affichage des emails sous forme de chips */}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
              marginBottom: 0
            }}
          >
            {emailList.slice(-4).map((emailItem, index) => (
              <Chip
                key={index}
                label={emailItem}
                onDelete={() => handleDeleteEmail(emailItem)}
                color="primary"
                size="small"
                sx={{ marginRight: 1 }}
              />
            ))}
          </Box>

          {/* Champ de texte pour saisir un email */}
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyUp={handleKeyPress}
            inputRef={inputRef} // Garder le focus
            size="small"
            sx={{
              "& .MuiOutlinedInput-root": {
                height: "40px",
              },
            }}
          />

          <TextField
            label="Objet"
            variant="outlined"
            fullWidth
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            size="small"
            sx={{
              "& .MuiOutlinedInput-root": {
                height: "40px",
              },
            }}
          />

          <TextField
            label="Message"
            variant="outlined"
            fullWidth
            multiline
            minRows={2}
            maxRows={6}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                height: "auto",
              },
            }}
          />

          <Button
            variant="outlined"
            color="success"
            fullWidth
            sx={{
              height: "44px",
              mt: '4px',
              textTransform: "none",
            }}
            type="submit"
            disabled={loading}
          >
            {loading ? <CircularProgress size={20} color="inherit" /> : "ENVOYER"}
          </Button>

          <Typography
            variant="body2"
            sx={{
              mt: 1,
              textAlign: "center",
              color: "gray",
              fontSize: "12px",
            }}
          >
            WiicMenu vous a envoyé un formulaire de mise en place de prélèvement automatique pour autorisation de prélèvement de futurs paiements depuis votre compte bancaire.
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RIBDialog;
